import React, { useEffect } from 'react'
import { ReportWrap } from './style'
import Tables from '../../Reuseable/Tables'
import { useFetch } from '../../../Hooks/useFetch'
import { useDispatch, useSelector } from 'react-redux'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment'
import { setDateRange, setReportFilter, setToBeFiltered } from '../../../redux/Global/actionCreator'
import { api, lambdaUrl } from '../../../Services/api'
import { Alert, Snackbar } from '@mui/material'
import { Button, Dropdown, Spinner } from 'react-bootstrap'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SelectBox from '../../Reuseable/SelectBox'
import CalenderSelect from '../../Reuseable/CalenderSelect'
import { queryClient } from '../../../App'
import NotificationReport from '../NotificationReport'

function convertArrayToCSV(array) {
    let csv = '';
    csv += Object.keys(array[0]).join(',') + '\n';
    array.forEach(item => {
        const row = Object.values(item).join(',');
        csv += row + '\n';
    });
    return csv;
}

const type1 = "BidleadLiveAuctionBidding"
const type2 = "BidleadLiveAuctionNoBidding"
const loadIncrement = 50

const Report = () => {

    const dispatch = useDispatch()
    const [filteredReportDataArray, setFilteredReportDataArray] = React.useState([])
    const [currentPagination, setCurrentPagination] = React.useState(0)
    const [openAlert, setOpenAlert] = React.useState(false)
    const [alertMsg, setAlertMsg] = React.useState("")
    const result = useSelector(state => state.Global.result)
    const { state: stateReportFilter, broker: brokerReportFilter } = useSelector(state => state.Global.reportFilter)
    const { state: stateToBeFiltered, broker: brokerToBeFiltered } = useSelector(state => state.Global.tobeFiltered);
    const { startDate, endDate } = useSelector(state => state.Global.dateRange)
    const pagination = { paginate: false, number: 0 }
    const [startDateValue, setStartDateValue] = React.useState(startDate)
    const [endDateValue, setEndDateValue] = React.useState(endDate)
    const [loadingMoreReport, setLoadingMoreReport] = React.useState(false)

    const stateOptions = stateToBeFiltered?.map((option, index) => {
        return {
            value: option,
            label: option,
            id: index + 1
        }
    })

    const brokerOptions = brokerToBeFiltered?.map((option) => {
        return {
            value: option.brokerID,
            label: option.brokerName,
            id: option.brokerId
        }
    })

    const handleStateFilter = (event) => {
        dispatch(setReportFilter({
            state: event,
            broker: brokerReportFilter
        }))
    }

    const handleBrokerFilter = (event) => {
        dispatch(setReportFilter({
            state: stateReportFilter,
            broker: event
        }))
    }

    const handleStartDate = (date) => {
        setStartDateValue(date)
        dispatch(setDateRange({
            startDate: moment(date).format("YYYY-MM-DD") === "Invalid date" ? null : moment(date).format("YYYY-MM-DD"),
            endDate: moment(endDateValue).format("YYYY-MM-DD") === "Invalid date" ? null : moment(endDateValue).format("YYYY-MM-DD")
        }))
    }

    const handleEndDate = (date) => {
        setEndDateValue(date)
        dispatch(setDateRange({
            startDate: moment(startDateValue).format("YYYY-MM-DD") === "Invalid date" ? null : moment(startDateValue).format("YYYY-MM-DD"),
            endDate: moment(date).format("YYYY-MM-DD") === "Invalid date" ? null : moment(date).format("YYYY-MM-DD")
        }))
    }

    const handleCloseAlert = () => {
        setOpenAlert(false)
        setAlertMsg("")
    }

    const openAlertMsg = (msg) => {
        setOpenAlert(true)
        setAlertMsg(msg)
    }

    const { data: reportData, isLoading } = useFetch(`getReport-${result === "won" ? type1 : type2}`,
        `${lambdaUrl}/2_bidlead_report/${result === "won" ? type1 : type2}/${loadIncrement}`,
        () => { }, () => { }, false, !queryClient.getQueryData([`getReport-${result === "won" ? type1 : type2}`]))

    useEffect(() => {
        if (reportData?.status) {
            dispatch(setToBeFiltered({
                state: [...new Set(reportData?.data.map(item => item["State Leads is in"]))],
                broker: result === "won" ? [...new Set(reportData?.data.map(item => item["Broker ID"]))].map((id) => {
                    return {
                        brokerID: id,
                        brokerName: reportData?.data.find(item => item["Broker ID"] === id)["Broker Name"]
                    }
                }) : null
            }))
        }
    }, [reportData, result, dispatch])

    const loadMoreReportData = async () => {
        setLoadingMoreReport(true)
        try {
            const response = await api.get(`${lambdaUrl}/2_bidlead_report/${result === "won" ? type1 : type2}/${reportData?.data.length + loadIncrement}`, {
                headers: {
                    "authorizationToken": localStorage.getItem("token"),
                },
            })
            setLoadingMoreReport(false)
            console.log("response  response-->", response);

            queryClient.setQueryData([`getReport-${result === "won" ? type1 : type2}`], (oldData) => {
                return {
                    status: oldData.status,
                    data: [...oldData.data, ...response.data.data]
                }
            })

        } catch (error) {
            setLoadingMoreReport(false)
            console.log("[LoadMore] error -->", error)
        }
    }

    const loadMore = {
        show: true,
        component: <div className="table_header text-center mt-2">
            <Button onClick={loadMoreReportData}>
                {loadingMoreReport ? <><Spinner animation="border" variant="light" size="sm" /> Loading More...</> : "Load More"}
            </Button>
        </div>
    }

    const handleDownload = () => {
        if (reportData?.data.length === 0) {
            openAlertMsg("No data to download")
            return
        }
        const csvData = convertArrayToCSV(reportData?.data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `${result}_report_${moment(new Date()).format("DD-MM-YYYY")}.csv`);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const filteredArray =
            reportData?.data
                .filter(item => {
                    if (stateReportFilter === null) return item
                    if (stateReportFilter.length === 0) return item
                    return stateReportFilter.map(item => item.value).includes(item["State Leads is in"])
                })
                .filter(item => {
                    if (brokerReportFilter === null) return item
                    if (brokerReportFilter.length === 0) return item
                    return brokerReportFilter.map(item => item.value).includes(item["Broker ID"])
                })
                .filter(item => {
                    const auctionDate = moment(item["Auction Date"], "DD-MM-YYYY").format("YYYY-MM-DD");
                    return startDate !== null ?
                        auctionDate >= startDate && auctionDate <= (endDate === null ? moment(new Date()).format("YYYY-MM-DD") : endDate)
                        : auctionDate <= (endDate === null ? moment(new Date()).format("YYYY-MM-DD") : endDate)
                });
        setFilteredReportDataArray(filteredArray)
    }, [startDate, endDate, reportData, stateReportFilter, brokerReportFilter])


    const tbodyData = filteredReportDataArray

    const columns = filteredReportDataArray?.length > 0 && Object.keys(filteredReportDataArray[0]).filter((item) => {
        return (item !== "Id") ? item : null //? removing Id column from columns 
    })

    const extra = null
    const header = <div className='report_header'>
        {false ? < h4 className="report_title">Report <span className='report_won_bid'><ArrowForwardIosIcon /> <span className='text-capitalize'>{result}</span> bid report </span></h4>
            : <div> {null}  </div>}
        <div className='d-flex justify-content-center align-items-center gap-2'>

            <Dropdown className="filter_dropdown" to="#" title="filter">
                <Dropdown.Toggle className="px-3" id="filter_dropdown">
                    <FilterAltIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className='filter_container'>
                        <div className='filter_item'>
                            <div className='filter_item_label'>State</div>
                            <SelectBox isMulti={true} options={stateOptions} value={stateReportFilter} onChange={handleStateFilter} />
                        </div>

                        <div className='filter_item'>
                            <div className='filter_item_label'>Start Date</div>
                            <CalenderSelect name="start-date" onChange={handleStartDate} value={startDateValue} />
                        </div>

                        <div className='filter_item'>
                            <div className='filter_item_label'>End Date</div>
                            <CalenderSelect name="end-date" onChange={handleEndDate} value={endDateValue} />
                        </div>

                        <div className='filter_item'>
                            <div className='filter_item_label'>Broker</div>
                            <SelectBox isMulti={true} options={brokerOptions} value={brokerReportFilter} onChange={handleBrokerFilter} />
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>

            <button className='report_csv_download' onClick={handleDownload}>
                Download CSV
            </button>
        </div>

    </div>

    const tbody = <tbody>{tbodyData?.length > 0 && tbodyData?.map((row, i) => {
        return (
            <tr key={i + 1 + ")" + row.leadUniqueId}>
                {reportData?.data && Object.keys(reportData?.data[0]).map((item, index) => {
                    return (item !== "Id") && <td key={index} className={`report_row row_${item.split(" ").join("_")}`}>{row[item]}</td>
                }
                )}
            </tr>
        )
    })}
    </tbody>

    const tableData = {
        header, loading: isLoading, pagination, currentPagination, length: filteredReportDataArray?.length,
        setPagination: setCurrentPagination, headerClass: "custom_header", notfound: "No data found",
        extra, columns, tbodyData, tbody, headerNoWrap: true, loadMore: loadMore
    }

    return (
        <ReportWrap>
            <Snackbar
                className="pt-5"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} variant="filled" severity="error">
                    {alertMsg}
                </Alert>
            </Snackbar>
            {result === "notification" ? <NotificationReport /> : <Tables {...tableData} />}

        </ReportWrap>
    )
}

export default Report